<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="612px"
  >
    <!--医生输入诊费-->
    <div class="main" v-if="title === '支付'">
      <p class="title">11咨询费用</p>
      <el-input
          placeholder="请输入诊疗费"
          prefix="￥"
          v-model="price"
          size="medium"
      >
        <template slot="prefix">￥</template>
      </el-input>
    </div>
    <!--病人确认费用-->
    <div class="main" v-if="title === '去支付'">
      <p class="title" >支付费用</p>
      <p class="price">￥ <span>50</span></p>
      <p class="tip"  v-if="pay_data" >该费用是本次治疗诊断费用，请及时去支付！</p>
      <p class="tip"  v-if="message1" >该费用是给对方赠送礼物，请及时去支付！</p>
    </div>
    <!--病人支付费用-->
    <div class="main" v-if="title === '确认支付'">
      <p class="title">支付金额</p>
      <p class="pay_text">
        <span>支付金额：{{total}}元</span>
        <span>优惠券：-{{couponNum}}元</span>
        <span class="red">实付金额：<span>{{total - couponNum}}</span>元</span>
      </p>
      <p class="title">优惠券</p>
      <div class="coupon_wrap">
        <el-scrollbar style="width:100%;height: 100%">
          <div class="coupons">
            <div class="coupon" v-for="(coupon,index) in coupons" :class="{choosed: currentIndex === index}" @click="currentIndex = index">
              <p>￥<span class="coupon_num">{{coupon.num}}</span> {{coupon.bill}}</p>
              <p>有效期至{{coupon.time}}</p>
              <p :class="{red: currentIndex !== index}">{{currentIndex === index ? '已使用' : '去使用'}}</p>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <p class="title">支付方式</p>
      <div class="payWays">
        <div class="wayItem" @click="currentWay = 'wx'">
          <el-image :src="require('../assets/pay/wx.jpg')"/>
          <i v-show="currentWay === 'wx'" class="el-icon-success"></i>
        </div>
        <div class="wayItem" @click="currentWay = 'zfb'">
          <el-image :src="require('../assets/pay/zfb.jpg')"/>
          <i v-show="currentWay === 'zfb'" class="el-icon-success"></i>
        </div>
        <div class="wayItem" @click="currentWay = 'online'">
          <el-image :src="require('../assets/pay/online.jpg')"/>
          <i v-show="currentWay === 'online'" class="el-icon-success"></i>
        </div>
      </div>

    </div>
    <!--底部操作按钮-->
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSend" v-if="title === '支付'">发送</el-button>
      <el-button type="primary" @click="goToPay" v-if="title === '去支付'">去支付</el-button>
	  <!-- 礼物支付 -->
	 <div v-if="message1">
      <el-button   type="primary" @click="handleSend33(message1)" v-if="title === '确认支付'">支付 </el-button>
    </div>
	<div v-if="pay_data">
	  <el-button   type="primary" @click="handleSend44(pay_data)" v-if="title === '确认支付'">支付 </el-button>
	</div>
	</span>
  </el-dialog>
</template>

<script>
  import axios from "axios"; 
  export default {
    name: 'Pay',
    components: {
    },
    props: ['message1','pay_data'],
    data() {
      return {
		  gifts:11,
        dialogVisible: false,
        title: '去支付',
        price: '',
        coupons: [
          {
            num: 10,
            time: '2021-08-31',
            bill: '预约咨询可用',
            choose: true
          },
          {
            num: 20,
            time: '2021-08-31',
            bill: '预约咨询可用',
            choose: false
          },
          {
            num: 30,
            time: '2021-08-31',
            bill: '预约咨询可用',
            choose: false
          },
          {
            num: 40,
            time: '2021-08-31',
            bill: '预约咨询可用',
            choose: false
          }
        ],
        currentIndex: 0,
        total: 50,
        currentWay: 'wx'
      }
    },
    computed :{
      couponNum() {
        return this.coupons[this.currentIndex].num
      }
    },
    methods: {
      open(){
        this.title = '去支付'
        this.dialogVisible = true
      },
	  
	  
	  //支付排名
	  handleSend44(e) { 
		  const params3 = new URLSearchParams();
		   			  
		   	params3.append('uid', sessionStorage.getItem('uid')); 
		  	 
		    //params3.append('id', e.id); 
		  
		  		 
		     axios.post(this.apiUrl+'/api/user/setPayRank',params3)
		     .then((response) => {  
				this.$message.success('操作成功！');
				window.location.reload();	
		     			  
		     }) 
		     .catch(function(error) {
		       console.log(error);
		     });
	  },
	  //支付礼物
	  handleSend33(e) {  
		  
			const params3 = new URLSearchParams();
             			  
             params3.append('uid', sessionStorage.getItem('uid')); 
	 
			 params3.append('bid', e.bid); 
	 
			 params3.append('name', e.name); 
		 
			 params3.append('url', e.url); 	
	 
			 params3.append('num', e.num); 
	 
			 params3.append('message', e.message); 
		 
	  		   axios.post(this.apiUrl+'/api/user/setPayGift',params3)
	  		   .then((response) => {  
	  		   	this.$message.success('操作成功！');
				window.location.reload();	
	  		   			  
	  		   }) 
	  		   .catch(function(error) {
	  		     console.log(error);
	  		   });
	  },
	  
      handleSend() {
		   const params = new URLSearchParams();
		   axios.post(this.apiUrl+'/api/user/setPayGift',this.gifts)
		   .then((response) => {  
		   	console.log(response.data.data);
		   	this.info = response.data.data; 
		   			  
		   }) 
		   .catch(function(error) {
		     console.log(error);
		   });
      },
      goToPay(){
		  // this.$refs.handleSend2();
		  //this.handleSend2();
		  // alert(message1.name)
        this.title = '确认支付'
      },
      handleWay(e){
        console.log(e);
      }
    }
  }
</script>

<style scoped lang="scss">
  .main{
    .title{
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      padding-bottom: 14px;
    }
    .el-input{
      margin-bottom: 90px;
    }
    .price{
      font-size: 18px;
      color: #333;
      padding-left: 20px;
      span{
        font-size: 40px;
      }
    }
    .tip{
      font-size: 12px;
      color: #FF1515;
      line-height: 17px;
      padding: 18px 0 59px;
    }
    .pay_text{
      color: #999;
      font-size: 18px;
      margin-bottom: 30px;
      &>span{
        margin-right: 48px;
      }
      .red{
        color: #FF2828;
        span{
          font-size: 40px;
        }
      }
    }
    .coupon_wrap{
      margin-bottom: 17px;
      width: 100%;
      height: 80px;
      .coupons{
        display: flex;
        height: 56px;
        .coupon{
          flex-shrink:0;
          width: 150px;
          height: 56px;
          margin-right: 24px;
          background-color: $green;
          font-size: 12px;
          color: #fff;
          text-align: center;
          line-height: 18px;
          padding-top: 4px;
          .coupon_num{
            font-size: 14px;
            padding-right: 10px;
          }
          .red{
            background-color: #FF2828;
            display: inline-block;
            position: relative;
            bottom: -2px;
            padding: 0 4px;
          }
        }
        .choosed{
          background-color: #A5E3E7;
        }
      }

    }
    .payWays{
      margin-bottom: 90px;
      display: flex;
      .wayItem{
        height: 55px;
        margin-right: 40px;
        position: relative;
        i{
          position: absolute;
          bottom: 10px;
          right: 0;
          font-size: 21px;
          color: #FF2828;
      }
      }
    }
  }

  /deep/ .el-dialog__header{
    background-color: $green;
    height: 33px;
    padding: 0 0 0 27px;
    .el-dialog__title{
      line-height: 33px;
      font-size: 12px;
      color: #fff;
    }
    .el-dialog__headerbtn{
      top: 8px;
      .el-dialog__close{
        color: #fff;
      }
    }
  }
  /deep/ .el-dialog__body{
    padding: 14px 20px;
    .el-input__prefix{
      left: 20px!important;
      line-height: 48px;
      font-size: 12px;
      color: #333;
    }
    .el-input--medium .el-input__inner{
      text-indent: 18px;
      height: 48px;
    }
  }
  /deep/ .el-dialog__footer{
    text-align: center;
    padding-bottom: 50px;
    .el-button--primary{
      width: 190px;
      height: 32px;
      background-color: $green;
      border-color: $green;
    }
  }
  /deep/ .el-scrollbar__wrap{
    overflow-y: hidden!important;
  }
</style>
